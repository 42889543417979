import store from './src/store'
import getUrlParam from './src/lib/getUrlParam'

import './src/css/tailwind.css'

export const onClientEntry = () => {
  // grab refcode from url if it exists, to pass through to Actblue links
  store.refcode = getUrlParam('refcode')
  store.refcode2 = getUrlParam('refcode2')
}
