import 'url-search-params-polyfill'

export default function getUrlParam(key) {
  if (typeof window === `undefined`) {
    return false
  }
  const urlParams = new URLSearchParams(window.location.search)
  for (const param of urlParams) {
    if (param[0] === key) return param[1]
  }
  return false
}
